import React, { useState } from 'react';
import ResetInput from './ResetInputButton';
import SendButton from './SendButton';

const hide = {
  display: 'none'
};

function SearchByName(props) {  
  const [isLoggedIn, setIsLoggedIn] = useState(false);  
  const [isDisabled, setIsDisabled] = useState(true);  
  const [radioOptions, setRadioOptions] = useState([]);
  const [target, setTarget] = useState();
  const [mailboxResponse, setMailboxResponse] = useState([]);

  const setBoxValue = (rcpUniqueId,isAllowSend) => {
    setIsDisabled(!isAllowSend);
    setTimeout(function(e){
      document.getElementById('boxnum').value = rcpUniqueId;
    },150);    
  }

  const handleChange = (newValue) => {
    setIsDisabled(newValue);
  };

  async function showAttachment()
  {
    let attachment = document.getElementById('attachment').value.replace(/^.*[\\\/]/, '');
    document.getElementById('fileInput').innerHTML = 'קובץ מצ"ב: ' + attachment;
  }
  
  async function validateBoxState() { 
    const input = document.getElementById('boxtxt');
    const error = document.getElementById('error');
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const rcpId = document.getElementById('boxtxt').value;
    const response = await fetch(API_ENDPOINT + '/getRecipient/?rcpText=' + rcpId,
      {
          method: 'GET',
      });
    const dataResponse = await response.json();
    setIsDisabled(true);
    setTarget('');
    if (dataResponse.error) {
        handleChange(1);
        setRadioOptions([]);
        setMailboxResponse(dataResponse.error);
    } else {            
        const formattedOptions = dataResponse.rcp.map(option => ({
          rcpId: option.rcpId,
          rcpUniqueId: option.rcpUniqueId,
          rcpName: option.rcpName,
          rcpInst: option.rcpInst,
          isAllowSend: option.isAllowSend,
          rcpMessageLastSentText: (option.rcpMessageLastSentText!='')?' - '+option.rcpMessageLastSentText:'',
        }));
        setRadioOptions(formattedOptions);
        setMailboxResponse('');
    }
  }

  return (
    <div>
        <h2>הקלד שם מלא או חלק ממנו</h2>
        <div class="controlpanel">
          <input onKeyUp={validateBoxState} type="text" class="boxtxt" id="boxtxt" maxLength="50" />
          <p id="error" style={hide}>Value must be between 0 and 100</p>
          <h2>צרף תמונה ו/או קובץ מסוג אחר</h2>
          <div style={{ position: 'relative' }} class="boxnum file">
            <input onChange={showAttachment} type="file" name="attachment" id="attachment" class="boxnum" style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: 1 }} />
            <label htmlFor="fileInput" class="fileInput" id='fileInput'>בחר/י קובץ</label>
          </div>
          <br /><br />
          <input type="hidden" name="boxnum" id="boxnum" value="" />
          <div class="items">
            <ResetInput type="txt" setTarget={setTarget} setRadioOptions={setRadioOptions} setIsDisabled={setIsDisabled}></ResetInput>
            <SendButton type="txt" setTarget={setTarget} isDisabled={isDisabled} setRadioOptions={setRadioOptions} setIsDisabled={setIsDisabled} onChange={handleChange}></SendButton>
          </div>
        </div>
        <div class="target" id="target">{target}
          {radioOptions.map(option => (
            <label key={option.rcpUniqueId} onClick={() => setBoxValue(option.rcpUniqueId,option.isAllowSend)}>
              <input type="radio" name="radioGroup" value={option.rcpUniqueId} />
              <strong>תיבה {option.rcpId}</strong>, {option.rcpName} ({option.rcpInst})<span class="rcpMessageLastSentText">{option.rcpMessageLastSentText}</span>
              <br />
            </label>
          ))}
        </div>
        <div><br />{mailboxResponse}</div>        
    </div>
  );
}

export default SearchByName;
