import React, { useState, useEffect } from 'react';
import ResetInput from './ResetInputButton';
import SendButton from './SendButton';
import Select from 'react-select';

const hide = {
  display: 'none'
};

function SearchByCategory(props) {  
  const [userCategories, setUserCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);  
  const [isDisabled, setIsDisabled] = useState(true);  
  const [radioOptions, setRadioOptions] = useState([]);
  const [target, setTarget] = useState();
  const [mailboxResponse, setMailboxResponse] = useState([]);

  async function loadCategories()
  {
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      const response = await fetch(API_ENDPOINT + '/getCategories',
      {
          method: 'GET',
      });
      const dataResponse = await response.json();
      const formattedOptions = dataResponse.categories.map((item) => ({
        value: item.categoryId,
        label: item.categoryName,
      }));
      setCategories(formattedOptions);
  }
  useEffect(() => {
    loadCategories();
  }, []);      

  const setBoxValue = (rcpUniqueId,isAllowSend) => {
    setIsDisabled(!isAllowSend);
    document.getElementById('boxnum').value = rcpUniqueId;
  }

  const handleChange = (newValue) => {
    setIsDisabled(newValue);
  };

  async function setUserCategoriesFunc(th)
  {
    setUserCategories(th);
    validateBoxState(th);
  }

  async function showAttachment()
  {
    let attachment = document.getElementById('attachment').value.replace(/^.*[\\\/]/, '');
    document.getElementById('fileInput').innerHTML = 'קובץ מצ"ב: ' + attachment;
  }
  
  async function validateBoxState(th) { 
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const categoryId = th.value;
    const response = await fetch(API_ENDPOINT + '/getRecipient/?categoryId=' + categoryId,
      {
          method: 'GET',
      });
    const dataResponse = await response.json();
    setIsDisabled(true);
    setTarget('');
    if (dataResponse.error) {
        handleChange(1);
        setMailboxResponse(dataResponse.error);
    } else {            
        setIsDisabled(false);
        setTarget('סך הכל ' + dataResponse.rcp.length + ' נמענים.');
        setMailboxResponse('');
    }
  }

  return (
    <div>
        <h2>בחר/י קטגוריה</h2>
        <div class="controlpanel">
        <Select
            options={categories}
            value={userCategories}
            onChange={setUserCategoriesFunc}
            inputId='rcpCategories'
            name='rcpCategories'
            isSearchable
            placeholder="קטגוריות"
          />
          <p id="error" style={hide}>Value must be between 0 and 100</p>
          <h2>צרף תמונה ו/או קובץ מסוג אחר</h2>
          <div style={{ position: 'relative' }} class="boxnum file">
            <input onChange={showAttachment} type="file" name="attachment" id="attachment" class="boxnum" style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: 1 }} />
            <label htmlFor="fileInput" class="fileInput" id='fileInput'>בחר/י קובץ</label>
          </div>
          <br /><br />
          <input type="hidden" name="boxnum" id="boxnum" value="" />
          <div class="items">
            <ResetInput type="cat" setTarget={setTarget} setRadioOptions={setRadioOptions} setIsDisabled={setIsDisabled}></ResetInput>
            <SendButton type="cat" setTarget={setTarget} isDisabled={isDisabled} setRadioOptions={setRadioOptions} setIsDisabled={setIsDisabled} onChange={handleChange}></SendButton>
          </div>
        </div>
        <div class="target" id="target">{target}</div>
        <div><br />{mailboxResponse}</div>
    </div>
  );
}

export default SearchByCategory;
