import React, { useState } from 'react';
import ResetInput from './ResetInputButton';
import SendButton from './SendButton';

const hide = {
  display: 'none'
};

function SearchByMailbox(props) {  
  const [isLoggedIn, setIsLoggedIn] = useState(false);  
  const [isDisabled, setIsDisabled] = useState(true);  
  const [radioOptions, setRadioOptions] = useState([]);
  const [target, setTarget] = useState([]);

  const handleChange = (newValue) => {
    setIsDisabled(newValue);
  };

  async function showAttachment()
  {
    let attachment = document.getElementById('attachment').value.replace(/^.*[\\\/]/, '');
    document.getElementById('fileInput').innerHTML = 'קובץ מצ"ב: ' + attachment;
  }

  async function validateBoxState()
  {    
    const input = document.getElementById('boxnumpublic');
    const error = document.getElementById('error');

    if (input.value < 0 || input.value > 9999) {
      error.style.display = 'block';
    } else {
      error.style.display = 'none';

      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      const rcpId = document.getElementById('boxnumpublic').value;
      const response = await fetch(API_ENDPOINT + '/getRecipient/?rcpId=' + rcpId,
      {
          method: 'GET',
      });
      const dataResponse = await response.json();
      setIsDisabled(true);      
      if (dataResponse.error) {
        handleChange(1);
        document.getElementById('target').innerHTML = dataResponse.error;
      } else {
        handleChange(0);
        let rcpString = '';
        if (dataResponse.rcp[0].rcpInst == "") 
        {
          rcpString = dataResponse.rcp[0].rcpName +  (dataResponse.rcp[0].rcpMessageLastSentText!=''?'<br>' + '<span style="color:red;font-size:24px;">'  + dataResponse.rcp[0].rcpMessageLastSentText  + '</span>':'');
        } else {
          rcpString = dataResponse.rcp[0].rcpInst + ", " + dataResponse.rcp[0].rcpName + (dataResponse.rcp[0].rcpMessageLastSentText!=''?'<br>' + '<span style="color:red;font-size:24px;">'  + dataResponse.rcp[0].rcpMessageLastSentText  + '</span>':'');
        }
        document.getElementById('boxnum').value = dataResponse.rcp[0].rcpUniqueId;
        setTarget('ההודעה תשלח ל - ' + rcpString);
        setIsDisabled((dataResponse.rcp[0].isAllowSend)?false:true);
      }
    }
  }

  return (
    <div>
        <h2>הקלד מספר תיבה</h2>
        <div class="controlpanel">
          <input onKeyUp={validateBoxState} type="number" class="boxnum" id="boxnumpublic" max="9999" maxLength="3" />
          <input type="number" class="boxnum hide" id="boxnum" />
          <p id="error" style={hide}>Value must be between 0 and 10000</p>
          <h2>צרף תמונה ו/או קובץ מסוג אחר</h2>
          <div style={{ position: 'relative' }} class="boxnum file">
            <input onChange={showAttachment} type="file" name="attachment" id="attachment" class="boxnum" style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: 1 }} />
            <label htmlFor="fileInput" class="fileInput" id='fileInput'>בחר/י קובץ</label>
          </div>
          <br /><br />
          <div class="items">
            {radioOptions}
            <ResetInput type="num" setTarget={setTarget} setRadioOptions={setRadioOptions} setIsDisabled={setIsDisabled}></ResetInput>
            <SendButton type="num" setTarget={setTarget} isDisabled={isDisabled} setRadioOptions={setRadioOptions} setIsDisabled={setIsDisabled} onChange={handleChange}></SendButton>
          </div>
        </div>
        <div class="target" id="target" dangerouslySetInnerHTML={{ __html: target }}></div>
    </div>
  );
}

export default SearchByMailbox;
