import React, { useState } from 'react';
import SearchByName from './SearchByName';
import SearchByMailbox from './SearchByMailbox';
import SearchByCategory from './SearchByCategory';

const hide = {
  display: 'none'
};

function validateBoxState()
{
  const input = document.getElementById('boxnum');
  const error = document.getElementById('error');

  input.addEventListener('change', () => {
    if (input.value < 0 || input.value > 999) {
      error.style.display = 'block';
    } else {
      error.style.display = 'none';
    }
  });
}

function SearchComponent() {
  const [menuActiveTab, setMenuActiveTab] = useState('SearchByName');
  const [selectedComponent, setSelectedComponent] = useState('SearchByName');
  const [showSearchByMailbox, setshowSearchByMailbox] = useState(true);
  function onSetState() {
    setshowSearchByMailbox(!showSearchByMailbox);
    setMenuActiveTab('SearchByName')
  }
  async function setSendMethod(sendMethod) {
    setSelectedComponent(sendMethod);
    setMenuActiveTab(sendMethod);
  }
  
  const renderSelectedComponent = () => {
    //alert(selectedComponent);    
    switch (selectedComponent) {
      case 'SearchByName':
        return <SearchByName />;
      case 'SearchByMailbox':
        return <SearchByMailbox />;
      case 'SearchByCategory':
        return <SearchByCategory />;
      default:
        return null;
    }
  };
  return (
    <div>
        <ul class="mainMenu">
          <li class={(menuActiveTab == 'SearchByName')?'active':''}><a href="#" onClick={() => setSendMethod('SearchByName')}>חיפוש לפי שם</a></li>
          <li class={(menuActiveTab == 'SearchByMailbox')?'active':''}><a href="#" onClick={() => setSendMethod('SearchByMailbox')}>חיפוש לפי מס' תיבה</a></li>
          <li class={(menuActiveTab == 'SearchByCategory')?'active':''}><a href="#" onClick={() => setSendMethod('SearchByCategory')}>חיפוש לפי קטגוריה</a></li>
        </ul>
        {renderSelectedComponent()}
    </div>
  );
}

export default SearchComponent;
