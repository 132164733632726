import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import Mng from './components/Mng';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

function App() { 
  return (
    <Router>
      <Routes>
        <Route exact path="/home" component={<Home />} />
        <Route path="/mng" component={Mng} />
      </Routes>
      <Home />
    </Router>
  ); 
}

export default App;
