import React from 'react';

function resetInput({type, setTarget,setRadioOptions,setIsDisabled}) {
    function resetInputField() {
        setTarget();
        setRadioOptions([]);
        setIsDisabled(true);
        document.getElementById('boxnum').value = '';
        if (type == 'txt')
        {            
            document.getElementById('boxtxt').value = '';
        }
    }
    return (
        <button onClick={resetInputField} class="clean">נקה</button>
    );
 }
  
 export default resetInput;
  