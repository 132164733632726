import React, { useState } from 'react';

function SendButton({type, setTarget, isDisabled, setRadioOptions, setIsDisabled}) {
    function sendButtonAction() {
        const fetchData = async () => {
            const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
            let categoryId = 0;
            try {
                categoryId = document.getElementsByName('rcpCategories')[0].value;
                categoryId = parseInt(categoryId);
            } catch (error) {
            }            
            const boxNum = document.getElementById('boxnum').value;
            if (((boxNum == '0')||(boxNum == ''))&&(categoryId == 0))
            {
                document.getElementById('target').innerHTML = 'יש לבחור נמען.';
            } else {
                const formData = new FormData();
                formData.append('rcpUniqueId', boxNum);
                formData.append('categoryId', categoryId);

                const fileInput = document.getElementById('attachment');
                const file = fileInput.files[0];
                if (file!='')
                {
                    formData.append('attachment', file);
                }                
                setTarget('נא להמתין...');

                const response = await fetch(API_ENDPOINT + '/sendMessage',
                {
                    method: 'POST',
                    body: formData
                });
                const jsonData = await response.json();
                
                document.getElementById('boxnum').value = '';
                if (type == 'txt')
                {            
                    document.getElementById('boxtxt').value = '';
                }
                setTarget('ההודעה נשלחה בהצלחה!');
                setRadioOptions([]);
                setIsDisabled(true);
            }
        };      
        fetchData();
    }
    return (
        <button class="send" disabled={isDisabled} onClick={sendButtonAction}>שלח</button>
    );
 }
  
 export default SendButton;
  