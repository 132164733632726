import React from 'react';
import SearchComponent from './SearchComponent';

function Home(props) {
    const fetchData = async () => {
        const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
        const response = await fetch(API_ENDPOINT + '/getDefaultMessage',
        {
            method: 'GET',
        });
        const dataResponse = await response.json();
        document.getElementById('taDefaultMessage').innerHTML = dataResponse.messageText;
    }
    fetchData();
    return (
        <div className="app">
          <header>
            <img src="logo.svg" class="logo" />
            <img src="menu.svg" class="menu" />
          </header>
          <main>
            <h1>ההודעה הנוכחית הנשלחת</h1>
            <textarea readonly="true" id="taDefaultMessage"></textarea>
            <SearchComponent />            
          </main>
        </div>
    )
 }
  
 export default Home;
  